@font-face {
  font-family: 'mastodon-font-display';
  src: local('Montserrat'),
    url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'mastodon-font-display';
  src: local('Montserrat Medium'),
    url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
