@font-face {
  font-family: 'mastodon-font-sans-serif';
  src: local('Roboto Italic'),
    url('../fonts/roboto/roboto-italic-webfont.woff2') format('woff2'),
    url('../fonts/roboto/roboto-italic-webfont.woff') format('woff'),
    url('../fonts/roboto/roboto-italic-webfont.ttf') format('truetype'),
    url('../fonts/roboto/roboto-italic-webfont.svg#roboto-italic-webfont') format('svg');
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'mastodon-font-sans-serif';
  src: local('Roboto Bold'),
    url('../fonts/roboto/roboto-bold-webfont.woff2') format('woff2'),
    url('../fonts/roboto/roboto-bold-webfont.woff') format('woff'),
    url('../fonts/roboto/roboto-bold-webfont.ttf') format('truetype'),
    url('../fonts/roboto/roboto-bold-webfont.svg#roboto-bold-webfont') format('svg');
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'mastodon-font-sans-serif';
  src: local('Roboto Medium'),
    url('../fonts/roboto/roboto-medium-webfont.woff2') format('woff2'),
    url('../fonts/roboto/roboto-medium-webfont.woff') format('woff'),
    url('../fonts/roboto/roboto-medium-webfont.ttf') format('truetype'),
    url('../fonts/roboto/roboto-medium-webfont.svg#roboto-medium-webfont') format('svg');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'mastodon-font-sans-serif';
  src: local('Roboto'),
    url('../fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
    url('../fonts/roboto/roboto-regular-webfont.woff') format('woff'),
    url('../fonts/roboto/roboto-regular-webfont.ttf') format('truetype'),
    url('../fonts/roboto/roboto-regular-webfont.svg#roboto-regular-webfont') format('svg');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
